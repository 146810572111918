<template>
  <div class="letterOfCommitment">
    <div class="title">
      <div class="en">letter of commitment</div>
      <div class="zh">{{ detailParams.title }}</div>
      <div class="line"></div>
    </div>
    <div class="content">
      {{ detailParams.content }}
    </div>
    <div class="submit-btn" @click="confirm">同意</div>
  </div>
</template>

<script>
import { getLetterOfCommitmentList } from "./api.js";
export default {
  name: "letterOfCommitment",
  components: {},
  props: {},
  data() {
    return {
      isConsent: true, //是否同意
      requestData: {
        curPage: 1,
        pageSize: 1,
        useFlag: 1,
      },
      detailParams: {},
    };
  },
  created() {
    this.getLetterOfCommitmentList();
  },
  mounted() {},
  methods: {
    getLetterOfCommitmentList() {
      this.$axios
        .get(getLetterOfCommitmentList, { params: this.requestData })
        .then((res) => {
          if (res.code == 200) {
            const { records } = res.data;
            this.detailParams = records[0];
            console.log("this.detailParams----->", this.detailParams);
          }
        });
    },

    //同意
    confirm() {
      this.$router.replace({
        name: "registerInfo",
        query: {
          isConsent: this.isConsent,
         
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.letterOfCommitment {
  width: 100%;
  min-height: 100vh;
  padding: 50px 30px 0 30px;
  padding-bottom: calc(150px + constant(safe-area-inset-bottom));
  padding-bottom: calc(150px + env(safe-area-inset-bottom));
  box-sizing: border-box;
  .title {
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    .en {
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 50px;
      margin-bottom: 8px;
    }
    .zh {
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 44px;
    }
    .line {
      margin-top: 32px;
      width: 100%;
      height: 2px;
      border-bottom: 2px dashed rgba(0, 0, 0, 0.5);
    }
  }
  .content {
    margin-top: 56px;
    box-sizing: border-box;
    width: 100%;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
  }
  .submit-btn {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 70px;
    width: 550px;
    height: 70px;
    background: #8a95a6;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 42px;
  }
}
</style>
